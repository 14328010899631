import { Navigate } from '@remix-run/react';

import {
  type DtoProduct,
  type DtoProductsResponse,
  type ModelsPrice,
} from '@lp-lib/api-service-client/public';

import { useOrgAnalytics } from '../../analytics/organization';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { type Organization, OrganizerRoleUtils } from '../../types';
import { formatCurrency } from '../../utils/currency';
import { DoubleRightArrow } from '../icons/Arrows';
import { Loading } from '../Loading';
import { useUpdateOrganizationSubscription } from '../Organization/hooks/organization';
import { useUser } from '../UserContext';
import { PriceWithMonthlySavings } from './ProductCard';
import { CHANGE_SUBSCRIPTION_URL, ProductUtils } from './utils';

export function SubscriptionChangeConfirm(props: {
  products: DtoProductsResponse;
  targetProductId: string | null;
  targetPriceId: string | null;
  trial: boolean;
  cancel: boolean;
  onCancelConfirmation: () => void;
  onConfirm: () => void;
  redirectTo: string;
}) {
  const organizer = useUser().organizer;
  const organization = organizer?.organization;
  if (!organization) {
    return (
      <div className='w-full h-full flex items-center justify-center text-white text-lg'>
        This feature is only available for organization members.
      </div>
    );
  }

  const canModifyPlan = OrganizerRoleUtils.isOwnerOrAdmin(organizer?.role);
  const contactBilling =
    organization.subscription.plan !== 'free' &&
    !organization.subscription.subscriptionId;

  if (!canModifyPlan || contactBilling) {
    return <Navigate to={CHANGE_SUBSCRIPTION_URL} />;
  }

  const targetProductId = props.cancel
    ? props.products.defaultProductId
    : props.targetProductId;

  const targetProduct = props.products.published.find(
    (p) => p.id === targetProductId
  );

  if (!targetProduct) {
    return <Navigate to={CHANGE_SUBSCRIPTION_URL} />;
  }

  const targetPrice = targetProduct.prices?.find(
    (p) => p.id === props.targetPriceId && !p.archived
  );

  if (props.targetPriceId && !targetPrice) {
    return <Navigate to={CHANGE_SUBSCRIPTION_URL} />;
  }

  return (
    <div className='w-full h-full text-white'>
      <div className='relative w-full h-full bg-black rounded-xl'>
        <div className='absolute w-full h-128 overflow-hidden bg-pay-wall' />
        <div className='relative w-full h-full'>
          <ConfirmPlanChange
            organization={organization}
            targetProduct={targetProduct}
            targetPrice={targetPrice ?? null}
            trial={props.trial}
            cancel={props.cancel}
            onCancelConfirmation={props.onCancelConfirmation}
            onConfirm={props.onConfirm}
            redirectTo={props.redirectTo}
          />
        </div>
      </div>
    </div>
  );
}

function ConfirmPlanChange(props: {
  organization: Organization;
  targetProduct: DtoProduct;
  targetPrice: ModelsPrice | null;
  trial: boolean;
  cancel: boolean;
  onCancelConfirmation: () => void;
  onConfirm: () => void;
  redirectTo: string;
}) {
  const {
    organization,
    targetProduct,
    targetPrice,
    onCancelConfirmation,
    onConfirm,
    redirectTo,
  } = props;

  const analytics = useOrgAnalytics();

  const { trigger, isMutating } = useUpdateOrganizationSubscription(
    organization.id,
    {
      checkoutSuccess: redirectTo,
      checkoutCancel: CHANGE_SUBSCRIPTION_URL,
    }
  );

  const handleUpgrade = useLiveCallback(
    async (product: DtoProduct, price: ModelsPrice | null, trial: boolean) => {
      analytics.trackUpgradeButtonClicked({
        productId: product.id,
        productName: product.name,
        priceId: price?.id,
        maxSeats: price?.maxSeats,
      });
      const resp = await trigger({ organization, product, price, trial });
      if (!resp || !resp.checkout) {
        onConfirm();
      }
    }
  );

  const { subscription } = organization;
  const currentProductName = subscription.productName;
  const currentPriceAmount = subscription.amount;
  const currentBillingInterval = subscription.billingInterval;
  const currentMaxSeats = organization.maxSize;

  return (
    <div className='relative w-full h-full flex flex-col items-center'>
      {isMutating && (
        <div className='absolute inset-0 bg-lp-black-001 flex items-center justify-center z-30'>
          <Loading text='' />
        </div>
      )}

      <div className='relative w-full h-full flex flex-col items-center gap-8'>
        <div className='pt-10 px-10 text-center'>
          <div className='text-3.5xl font-bold'>Plan Updates</div>
          <div className='pt-1 font-bold'>
            Here’s how we’re changing your plan today
          </div>
        </div>

        <div className='px-10 py-4 flex items-center gap-5'>
          <div>
            <div className='bg-layer-001 rounded-md w-80 shadow-xl'>
              <div className='bg-secondary rounded-t-md px-4 py-2 font-medium'>
                Current Plan
              </div>
              <div className='p-4 flex flex-col gap-1'>
                <div className='font-bold'>{currentProductName}</div>
                <div className='text-2xl font-bold'>
                  {!currentPriceAmount || currentPriceAmount === 0 ? (
                    <>Free</>
                  ) : (
                    <>
                      {formatCurrency(currentPriceAmount)}
                      {currentBillingInterval && (
                        <span className='font-normal text-base text-icon-gray'>
                          /{ProductUtils.FormatInterval(currentBillingInterval)}
                        </span>
                      )}
                    </>
                  )}
                </div>
                <div className='h-6'>
                  {currentMaxSeats && <>Up to {currentMaxSeats} users</>}
                </div>
              </div>
            </div>
            <div className='h-10' />
          </div>

          <DoubleRightArrow className='w-8 h-8 fill-current' />

          <div>
            <div className='bg-layer-001 rounded-md w-80 transform shadow-xl'>
              <div className='bg-primary rounded-t-md px-4 py-2 font-medium'>
                New Plan
              </div>
              <div className='p-4 flex flex-col gap-1'>
                <div className='font-bold'>{targetProduct.name}</div>
                <div className='text-2xl font-bold'>
                  {targetPrice === null || targetPrice.amount === 0 ? (
                    <>Free</>
                  ) : (
                    <PriceWithMonthlySavings
                      product={targetProduct}
                      price={targetPrice}
                      className=''
                    />
                  )}
                </div>
                <div className='h-6'>
                  {targetPrice?.maxSeats && (
                    <>Up to {targetPrice?.maxSeats} users</>
                  )}
                </div>
              </div>
            </div>
            <div className='h-10'>
              {props.trial &&
                organization.canTrial &&
                targetProduct.trialPeriodDays &&
                targetProduct.trialPeriodDays > 0 && (
                  <div className='flex items-center justify-center text-center font-bold text-tertiary'>
                    Free {targetProduct.trialPeriodDays} day trial
                  </div>
                )}
            </div>
          </div>
        </div>

        <div className='px-10 w-3/4 xl:w-1/2 text-center'>
          You are about to change your plan to{' '}
          <strong>{targetProduct.name}</strong>.
          {subscription.subscriptionId &&
          (targetPrice === null || targetPrice.amount === 0) ? (
            <>
              {' '}
              Downgrading to <strong>{targetProduct.name}</strong> will cancel
              your subscription, however, you will retain access to your current
              plan until your next billing cycle.
            </>
          ) : !subscription.subscriptionId ? (
            <> Click 'Continue' to checkout.</>
          ) : currentPriceAmount ? (
            <>
              {' '}
              By clicking 'Continue' you will be charged a prorated rate based
              on the remaining time in your current billing cycle.
            </>
          ) : null}
        </div>
        <footer className='pb-10 px-10 w-full flex items-center justify-center gap-5'>
          <button
            type='button'
            className='btn h-10 w-40 btn-secondary'
            onClick={onCancelConfirmation}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn h-10 w-40 btn-primary'
            onClick={() =>
              handleUpgrade(targetProduct, targetPrice, props.trial)
            }
          >
            Continue
          </button>
        </footer>
      </div>
    </div>
  );
}
